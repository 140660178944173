import React, { useReducer, useEffect } from "react";
import { format } from 'date-fns'
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    Backdrop,
    CircularProgress
} from "@mui/material";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import BannerImage from '../../../assets/do-to-win/do2win.png';
import secondBannerImage from '../../../assets/do-to-win/banner.png';
import logo from '../../../assets/do-to-win/logo.svg'
import { getGiveAwayCampaignList } from "../../../api/maas";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import DocumentMeta from 'react-document-meta';



const initialState = {
    giveAwayCampaignList: [],
    isLoading: false,
    page: 0,
    totalPage: 0,
};

const PAGE_SIZE = 10

const ApplyContainer = () => {
    const [state, setState] = useReducer(
        (_state, action) => ({ ..._state, ...action }),
        initialState
    );

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCampaignList = () => {
            if (!state.isLoading) {
                setState({ isLoading: true });
                getGiveAwayCampaignList(state.page, PAGE_SIZE).then(val => {
                    val = val.json;
                    let totalPage = parseInt(val['count'] / PAGE_SIZE);
                    if (val['count'] % PAGE_SIZE > 0) {
                        totalPage += 1;
                    }
                    if ('results' in val) {
                        setState({ isLoading: false, giveAwayCampaignList: val['results'], totalPage });
                    } else {
                        setState({ isLoading: false });
                    }
                }).catch(err => {
                    console.log('Err: ', err);
                    setState({ isLoading: false, giveAwayCampaignList: [] });
                })
            }
        }
        fetchCampaignList();
    }, [state.page]);

    const meta = {
      title: 'Axie Giveaway and Win Axie NFT with mJJ - metaverse Job Japan',
      description: 'Join the Axie Giveaway and Win NFTs for Your Own Team - Sign up Now with mJJ! Sign up for NFT Giveaways.',
      canonical: 'https://metaversejobjapan.io/axie-giveaway/',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,meta,document,html,tags'
        }
      }
    };

    return (
        <DocumentMeta {...meta}>
            <Container
            component="main"
            maxWidth="sm"
            style={{
                minHeight: "100vh",
                fontFamily: "product_sans",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                maxWidth: "100vw",
                margin: "0px",
                padding: "0px"
            }}>
            <Header/>
            <section id="mainvisual">
                    <div class="mainvisual-inner">
                        <div class="mainvisual-text-area">
                            <div id="logo">
                                <a href="/" target="_blank">
                                    <img src={logo} alt="metaverse job japan"/>
                                </a>
                            </div>
                            <h1>Axie Give Away</h1>
                            <p class="block-lead-text">
                                Sign up Now! To Win Axie Team<br/>
                            </p>
                            <p class="block-lead-text">
                                アクシーをGetしよう!<br/>
                            </p>
                            <div class="btn-signup">
                                <a href="/apply" target="_blank" rel="noreferrer">
                                    Sign up
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            <Box
                maxWidth="sm"
                md={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px 0px",
                    margin: "auto",
                    flex: "1",
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px 15px",
                    margin: "auto",
                    flex: "1",
                }}
            >
                
                {/* <div style={{textAlign: 'left', width: '100%'}}>
                    <a href="https://metaversejobjapan.io">← Home</a>
                    <span style={{float: 'right'}}>
                        <a href="https://metaversejobjapan.io/hot-games/">Games →</a>
                    </span>
                </div> */}
{/* 
                <img
                    src={BannerImage}
                    alt="do to win logo"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", margin: "10px 0px", width: "100%", }}>
                    <a href="https://metaversejobjapan.io/do-to-win-reward/"> > View Details and Rewards </a>
                </div>

                <img
                    src={secondBannerImage}
                    alt="do to win logo"
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginBottom: 30,
                        marginTop: 30
                    }}
                />

                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", margin: "10px 0px 5px 0px", width: "100%", }}>
                    <a href="https://metaversejobjapan.io/lets-celebrate-the-do-to-win-winners%E3%80%80/"> > View Happy Winners</a>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", margin: "5px 0px 10px 0px", width: "100%", }}>
                    <a href="https://metaversejobjapan.io/faq/do-to-win-general/"> > FAQ</a>
                </div> */}
                <Typography component="h1" variant="h5" margin="10px 0px 5px 0px" fontFamily="product_sans_bold" fontSize="25px" color="red" textAlign="center">
                    Giveaway
                </Typography>
                <Typography component="h1" variant="h5" margin="5px 0px 0px 0px" fontFamily="product_sans_bold" fontSize="20px" textAlign="center">
                    Pick your Favorite Axie Giveaway and Sign up Now!
                </Typography>
                <Typography component="h2" variant="h5" margin="0px 0px 15px 0px" fontFamily="product_sans" fontSize="14px" textAlign="center">
                    Do a small task and Win Axie NFT !
                </Typography>

                {
                    state.giveAwayCampaignList.map((item, index) => {
                        let btnText;
                        let btnClass;
                        let isEnabled;

                        switch (item.status) {
                            case 'ACTIVE':
                                btnText = "Sign up !";
                                btnClass = "giveaway-list-btn-sign-up";
                                isEnabled = true;
                                break;
                            case 'UPCOMING':
                                btnText = "Upcoming"
                                btnClass = "";
                                isEnabled = false;
                                break;
                            default:
                                btnText = "Ended";
                                btnClass = "giveaway-list-btn-ended";
                                isEnabled = false;
                        }

                        return (
                            <div key={index} style={{
                                display: "flex",
                                flexDirection: "column",
                                border: "2px solid #00000038",
                                width: "100%",
                                marginBottom: "12px",
                            }}>
                                <h3 style={{ margin: "0px", borderBottom: "2px solid #00000038", paddingLeft: "5px", }} className='giveaway-item'>
                                    {item.name}
                                </h3>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "10px 10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <img src={item.image} alt="axie" style={{ width: "50px", height: "50px", objectFit: "cover", }} />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexGrow: 1,
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        paddingLeft: "10px",
                                    }}>
                                        <span style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                            <TipsAndUpdatesOutlinedIcon style={{ marginRight: "8px", fontSize: "21px", }} />
                                            {item.token_per_winner * item.max_winners} {item.token === 'USDC' ? item.token : 'NFT'} for {item.max_winners} Winners!
                                        </span>
                                        <span style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "5px",
                                        }}>
                                            <CalendarMonthOutlinedIcon style={{ marginRight: "8px", fontSize: "21px", }} />
                                            {format(new Date(item.start_date), 'yyyy/MM/dd')}- {format(new Date(item.end_date), 'MM/dd')}
                                        </span>
                                    </div>
                                    <div className={isEnabled ? `giveaway-list-btn ${btnClass}` : `giveaway-list-btn ${btnClass} giveaway-list-btn-disabled`}
                                        onClick={e => {
                                            e.preventDefault();
                                            if (isEnabled) {
                                                navigate(`/axie-giveaway/${item.id}`)
                                            }
                                        }}
                                    >
                                        {btnText}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="page-list-container">
                    {
                        Array.from(new Array(state.totalPage)).map((item, index) => {
                            return (
                                <div className={state.page === index ? "giveaway-page-list-item-btn giveaway-page-list-item-btn-selected" : "giveaway-page-list-item-btn"} key={index} onClick={(e) => {
                                    e.preventDefault();
                                    setState({ page: index });
                                }}>
                                    {index + 1}
                                </div>
                            )
                        })
                    }
                    <div className={!(state.page + 1 < state.totalPage) ? "giveaway-page-list-item-btn giveaway-page-list-item-btn-disabled" : "giveaway-page-list-item-btn"}
                        onClick={e => {
                            e.preventDefault();
                            if (!(state.page + 1 < state.totalPage)) {
                                return;
                            }
                            setState({ page: state.page + 1 });
                        }}
                    >
                        Next >
                    </div>
                </div>
            </Box>
            <div class="section-inner">
                <div class="box-grid">
                    <div class="block-bg-gray block-conditions">
                        <p class="block-title">Give away conditions</p>
                        <ul class="block-num-list">
                            <li>Axie NFT will be shared to given wallet ID so connect your wallet sign up.</li>
                            <li>Winners will be randomly selected.</li>
                            <li>Filling form multiple time will not increase the win rate.</li>
                            <li>Follow social media pages to get notified.</li>
                            <li>One Axie giveaway only one sign up.</li>
                        </ul>
                    </div>
                    <div class="btn-signup">
                        <a href="/apply" target="_blank" rel="noreferrer">
                            Sign up
                        </a>
                    </div>
                    <div class="block-bg-border">
                        <div class="block-bottom">
                            <p class="block-wallet-text">You will need Crypto Wallet to receive Axie.<br/>If you do not have one <span class="strong-text">create now.</span></p>
                            <ul class="block-wallets">
                                <li><a href="https://metamask.io/" target="_blank" class="btn-metamask" rel="noreferrer">Metamask</a></li>
                                <li><a href="https://trustwallet.com/" target="_blank" class="btn-trust" rel="noreferrer">Trust Wallet</a></li>
                            </ul>
                            <p class="block-info-label">Axie is not Done yet!</p>
                            <dl class="block-info-list">
                                <dt>Total Market cap</dt>
                                <dd>$679,776,536</dd>
                                <dt>24 Hr volume</dt>
                                <dd>2489.11</dd>
                                <dt>Active players</dt>
                                <dd>468,805</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={state.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
        </DocumentMeta>
        
    );
};

export default ApplyContainer;
