import { createSlice, createAction } from '@reduxjs/toolkit'
import API from '../../../api/main';
// import { setSnackbarState } from '../../dashboardModule/redux/redux';

// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loginState: 0,
    loginTime: 0,
    loading: false,
    emailErr: false,
  },
  reducers: {
    setLoading: (state, action) => {
        state.loading = action.payload;
        state.emailErr = false;
    },
    setEmailErr: (state, action) => {
        state.emailErr = action.payload;
        state.loading = false;
    },
    setLoginState: (state, action) => {
        state.loginState = action.payload;
        state.loading = false;
        state.emailErr = false;
    },
    loginSuccess: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.user = action.payload;
      state.loading = false;
      state.emailErr = false;
      state.loginState += 1;
      state.loginTime = Date.now() + 60 * 60 * 1000;
    },
    logoutSuccess: (state, action) =>  {
      localStorage.removeItem('token');
      state.user = null;
      state.loading = false;
      state.emailErr = false;
      state.loginTime = 0;
      state.loginState = 0;
    },
  },
});
export default slice.reducer
// Actions
const { loginSuccess, logoutSuccess, setLoading, setEmailErr, setLoginState } = slice.actions
export const sendEmail = ({ email }) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await API.post('/send_otp_by_email/', { email })
    if (res.data['email'] && (res.status >= 200 && res.status < 400)) {
      // dispatch(setSnackbarState({ open: true, message: "OTP sent to your email, please check" }))
      return dispatch(setLoginState(1));
    }
    if (res.data['email'] && Array.isArray(res.data['email']) && !(res.status >= 200 && res.status < 400)) {
      throw Error(res.data['email'][0]);
    }
    throw Error('Email not found, register with us');
  } catch (e) {
    dispatch(setEmailErr(true));
    // dispatch(setSnackbarState({ open: true, message: e.message }))
  }
}

export const sendEmailWithPassCode = ({ email, passCode }) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await API.post('/verify_otp_and_email/', { email, pin: passCode })
    if (res.status >= 200 && res.status < 400) {
      return dispatch(loginSuccess({...res.data}));
    }
    if (res.data['non_field_errors'] !== null && Array.isArray(res.data['non_field_errors']) && !(res.status >= 200 && res.status < 400)) {
      throw Error(res.data['non_field_errors'][0]);
    }
    throw Error('OTP not found');
  } catch (e) {
    dispatch(setLoading(false));
    // dispatch(setSnackbarState({ open: true, message: e.message }))
  }
}

export const logout = () => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(logoutSuccess())
  } catch (e) {
    dispatch(setLoading(false));
    // dispatch(setSnackbarState({ open: true, message: e.message }))
  }
}

export const updateLoginState = (loginState) => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(setLoginState(loginState))
  } catch (e) {
    dispatch(setLoading(false));
    // dispatch(setSnackbarState({ open: true, message: e.message }))
  }
};

export const logoutAuth = createAction('auth/logout')

export const selectUser = state => state.userReducer;
