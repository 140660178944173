export const prepareApiResponse = async response => {
  const contentType = response.headers.get('content-type');
  return {
    ok: response.ok,
    json:
      contentType && contentType.includes('application/json')
        ? await response.json()
        : {},
  };
};
