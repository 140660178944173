import React, { memo } from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";

import { IconButton } from "@mui/material";
import ToDoListIcon from '../../assets/do-to-win/todoListIcon.svg';
import logoBlackImage from '../../assets/do-to-win/logo-black.jpg';
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const isMobile = window.innerHeight > window.innerWidth;

const ApplySuccessContainer = ({ closeBtn, campaign, is_account_created }) => {

    return (
        <Stack
            direction="column"
            alignItems="center"
            style={{ height: "100%", color: "black", fontFamily: "product_sans" }}
        >
            <Header/>
            {
                closeBtn && <div className="close-btn-popup-modal">
                    <IconButton style={{ color: "#7E7E7E" }} onClick={(e) => {
                        e && e.preventDefault();
                        window.location = "https://metaversejobjapan.io/axie-giveaway";
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", textAlign: "center", margin: "40px", }}>
                <img
                    src={logoBlackImage}
                    alt="logoBlackImage"
                    style={{
                        width: 264
                    }}
                />

                <img
                    src={ToDoListIcon}
                    alt="ToDoListIcon"
                    style={{
                        marginTop: 20,
                        marginBottom: 20
                    }}
                />

                {/*<PlaylistAddCheckIcon style={{fontSize: "64px", color: "green", }} />*/}
                <p style={{marginTop: "0px", marginBottom: "40px", fontSize: '36px', lineHeight: '52px', fontWeight: '500' }}>
                    <b>Do Your Task</b><br/>
                    to complete your sign up
                    </p>
                <div
                    className="flex-center ui-click-here-btn"
                    onClick={() => {
                        window.location = campaign.to_do_post_link;
                    }}
                    style={{padding: '8px 16px'}}
                >
                    <span style={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}> 
                        <i className="ui-arrow"></i>
                        GO TO TASK
                    </span>
                </div>
                <span style={{ fontWeight: "normal", fontSize: "14px", marginTop: "6px", }}> Click to see the task </span>
                {is_account_created && <span style={{ fontWeight: 700, fontSize: "14px", marginTop: "32px", color: '#EA5505' }}>
                    Please verify email {isMobile && <br/>}
                    to be selected as a winner.
                </span>}
            </div>
            <Footer/>
        </Stack>
    );
}

ApplySuccessContainer.propsType = {
    closeBtn: PropTypes.bool,
    campaign: PropTypes.object.isRequired
};

export default memo(ApplySuccessContainer);
