import {prepareApiResponse} from "./utils";

export const applyForGiveaway = async jsonData => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/apply-for-giveaway/`,
            {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};

export const getGiveAwayCampaignList = async (page, limit) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/giveaway-campaign/?limit=${limit}&offset=${page*limit}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};

export const getGiveAwayCampaignItem = async (itemId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/giveaway-campaign/${itemId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};

export const createRandomMessage = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/create-random-message/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};

export const checkIfWalletExists = async jsonData => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/check-if-wallet-exists/`,
            {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};

export const giveawayApplicantSignUp = async jsonData => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/maas/giveaway-applicant/`,
            {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }
        );
        return prepareApiResponse(response);
    } catch (error) {
        console.error(error);
    }
};