import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react'

import { wagmiClient, ethereumClient, projectId } from './wallet';
// import { client } from './wallet/test';
import store from "./store";
import { ProvideAuth } from "./hooks/useAuth";
import CenterLoader from "./components/loader";
import reportWebVitals from "./reportWebVitals";

const persistor = persistStore(store);

const container = document.getElementById("root");

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<CenterLoader />} persistor={persistor}>
        <BrowserRouter>
          <HelmetProvider>
            <ProvideAuth>
              <WagmiConfig client={wagmiClient}>
                <App />
              </WagmiConfig>
              <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                enableNetworkView={true}
                cacheProvider={false}
              />
            </ProvideAuth>
          </HelmetProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
