import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import userReducer from "../modules/authModule/redux/redux";
import dashboardReducer from "../modules/dashboardModule/redux/redux";

const reducer = combineReducers({
  // here we will be adding reducers
  userReducer,
  dashboardReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') { // check for action type
    state = undefined;
  }
  return reducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
      process.env.NODE_ENV !== "production"
          ? getDefaultMiddleware({ serializableCheck: false }).concat(
              require("redux-logger").default
          )
          : getDefaultMiddleware({ serializableCheck: false }),
});
export default store;