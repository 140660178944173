import * as React from "react";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
// import Slide from "@mui/material/Slide";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";

// components
import CenterLoader from "../components/loader";
// import Logo from "../components/Logo";
// import Navbar from "../components/Navbar";
// theme
import ThemeProvider from "../theme";

// hooks
// import { useAuth } from "../hooks/useAuth";

// redux
// import { useSelector, useDispatch } from "react-redux";
// import {
//   snackBarLayoutSelector,
//   setSnackbarState,
// } from "../modules/dashboardModule/redux/redux";

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  // minHeight: "100vh",
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up("lg")]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

// function SlideTransition(props) {
//   return <Slide {...props} direction="up" />;
// }

// ----------------------------------------------------------------------

BaseLayout.propTypes = {
  customNavbar: PropTypes.element,
};

export default function BaseLayout({ customNavbar }) {
  // const auth = useAuth();
  // const location = useLocation();
  // const navigator = useNavigate();

  // const dispatch = useDispatch();

  // const snackBarSelector = useSelector(snackBarLayoutSelector);

  // React.useEffect(() => {
  //   if (!auth.user && location.pathname !== "/") {
  //     navigator("/", { replace: true });
  //   } else if (auth.user !== null && location.pathname === "/") {
  //     navigator("/dashboard", { replace: true });
  //   }
  // }, [auth, auth.user, location.pathname, navigator]);

  return (
    <ThemeProvider>
      <RootStyle>
        <MainStyle>
          <React.Suspense fallback={<CenterLoader />}>
            <Outlet />
          </React.Suspense>
        </MainStyle>
      </RootStyle>
    </ThemeProvider>
  );
}
