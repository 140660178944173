import React from "react";
import PropTypes from "prop-types";

// material
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

// hooks
import useResponsive from "../hooks/useResponsive";

// components
import { CustomCircularProgress } from "./loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

DialogContainer.propsType = {
  DialogInnerContent: PropTypes.element.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  closeBtn: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isNotCloseByOutSide: PropTypes.bool,
};

export function DialogContainer({
  dialogTitle,
  isOpen,
  setOpen,
  DialogInnerContent,
  closeBtn,
  isNotCloseByOutSide,
}) {
  const fullScreen = useResponsive("down", "md");

  const handleClose = (event, reason) => {
    if (reason && isNotCloseByOutSide && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {dialogTitle && (
        <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
      )}
      <DialogContent>
        {DialogInnerContent && <DialogInnerContent />}
      </DialogContent>
      {closeBtn && (
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}


FullScreenDialogContainer.propsType = {
  DialogInnerContent: PropTypes.element.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  closeBtn: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isNotCloseByOutSide: PropTypes.bool,
};

export function FullScreenDialogContainer({
  dialogTitle,
  isOpen,
  setOpen,
  DialogInnerContent,
  closeBtn,
  isNotCloseByOutSide,
}) {
  const handleClose = (event, reason) => {
    if (reason && isNotCloseByOutSide && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={true}
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {dialogTitle && (
        <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
      )}
      <DialogContent
        style={{ background: "white", padding: 0, }}
      >
        {DialogInnerContent && <DialogInnerContent />}
      </DialogContent>
      {closeBtn && (
        <DialogActions
          style={{ background: "white" }}
        >
          <Button autoFocus onClick={handleClose} style={{ color: "black", }}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

LoaderDialog.propsType = {
  dialogText: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  closeBtn: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export function LoaderDialog({
  dialogTitle,
  isOpen,
  setOpen,
  dialogText,
  closeBtn,
  closeByOutSide
}) {
  return (
    <DialogContainer
      dialogTitle={dialogTitle}
      closeBtn={closeBtn}
      isOpen={isOpen}
      setOpen={setOpen}
      isNotCloseByOutSide={true}
      DialogInnerContent={() => (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <CustomCircularProgress />
          <Typography variant="subtitle2" noWrap>
            {dialogText}
          </Typography>
        </Stack>
      )}
    />
  );
}
