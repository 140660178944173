import { memo, useState } from "react";
import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import giveawaySubmittedSVG from '../../assets/giveaway_submitted.svg';
import facebookSVG from '../../assets/facebook.svg';
import discordSVG from '../../assets/discord.svg';
import twitterSVG from '../../assets/twitter.svg';
import GiveAwaySuccessBG from '../../assets/dialog_bg.svg';
import Header from "../../components/Header";
import Footer from "../../components/Footer";


const ApplyFailedContainer = ({ closeBtn, setOpen }) => {
    const [joinDiscord, setJoinDiscord] = useState(false);

    return (
        <Stack
            direction="column"
            alignItems="center"
            style={{ height: "100%", color: "black", fontFamily: "product_sans" }}
        >
            <Header/>
            {
                closeBtn && <div className="close-btn-popup-modal">
                    <IconButton style={{ color: "#7E7E7E" }} onClick={(e) => {
                        e && e.preventDefault();
                        setOpen(false);
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", textAlign: "center", margin: "40px", }}>
                <img alt="success" src={giveawaySubmittedSVG} />
                <h1 style={{fontFamily: "google_sansbold",}}>You are already signed up !</h1>
                <div className="social-icons-failed">
                    <span style={{ color: "#EA5505", fontWeight: "bold", fontFamily: "google_sansbold", }}>
                        Next Giveaway updates on
                    </span>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <a href="https://facebook.com" target="_blank" rel="noreferrer" style={{ cursor: "pointer", margin: "0px 15px", }}><img src={facebookSVG} style={{ width: "18px", height: "18px", }} alt="facebook icon" /></a>
                    <a href="https://twitter.com" target="_blank" rel="noreferrer" style={{ cursor: "pointer", margin: "0px 0px", }}><img src={twitterSVG} style={{ width: "18px", height: "18px", }} alt="twitter icon" /></a>
                    <span onClick={() => setJoinDiscord(true)} style={{ cursor: "pointer", margin: "0px 15px", }}><img src={discordSVG} style={{ width: "18px", height: "18px", }} alt="discord icon" /></span>
                    </div>
                </div>
            </div>
            <div className="background-container" style={{ width: "100%" }}>
                <img src={GiveAwaySuccessBG} alt="giveaway-bg" />
            </div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={joinDiscord}
                onClick={() => setJoinDiscord(false)}
            >
                <Stack spacing={1} style={{ height: "100%" }}>
                    <iframe
                        title="Connect to Discord server"
                        src="https://discord.com/widget?id=894910457834508288&theme=dark"
                        width="350"
                        height="500"
                        allowtransparency="true"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                    />
                </Stack>
            </Backdrop>
            <Footer/>
        </Stack>
    );
}

ApplyFailedContainer.propsType = {
    closeBtn: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
};

export default memo(ApplyFailedContainer);