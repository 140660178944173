import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";
import API from "../../../api/main";
import { logoutAuth } from "../../authModule/redux/redux";
// Slice
const slice = createSlice({
    name: "dashboard",
    initialState: {
        userInfo: {},
        userAxie: [],
        warehouseAxie: [],
        totalWarehouseAxie: 1,
        loading: false,
        metamask_permission: false,
        counter: 0,
        snack: {
            open: false,
            vertical: "bottom",
            message: "",
            axieExchangeError: -1,
            severity: "info",
            horizontal: "center",
        },
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        decrementCounter: (state, action) => {
            state.counter -= 1;
        },
        setSnack: (state, action) => {
            state.loading = false;
            state.snack = { ...state.snack, ...action.payload, axieExchangeError: action.payload.axieExchangeError !== -1 ? action.payload.axieExchangeError : -1, };
        },
        indexPageRequestSuccess: (state, action) => {
            state.loading = false;
            state.userAxie = action.payload.userAxie;
            state.userInfo = action.payload.userInfo;
            state.metamask_permission = action.payload.metamask_permission;
            state.counter = action.payload.counter
        },
        updateUserInfo: (state, action) => {
            state.loading = false;
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
        warehouseAxieRequestSuccess: (state, action) => {
            state.loading = false;
            state.warehouseAxie = action.payload.results;
            state.snack.axieExchangeError = -1;
            state.totalWarehouseAxie = action.payload.count;
        },
    },
});
export default slice.reducer;
// Actions
const {
    indexPageRequestSuccess,
    updateUserInfo,
    warehouseAxieRequestSuccess,
    setLoading,
    setSnack,
    decrementCounter,
} = slice.actions;

export const requestUserInfo = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await API.get("/api/user/");
        if ((res.status >= 200 && res.status < 400) && !res.data["detail"]) {
            return dispatch(indexPageRequestSuccess(res.data));
        }
        if (res.data["detail"] && !(res.status >= 200 && res.status < 400)) {
            throw Error(res.data["detail"]);
        }
        throw Error("internal server error");
    } catch (e) {
        dispatch(setSnackbarState({ open: true, message: e.message }));
    }
};

export const updateUserInfoAction = (userInfo) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        console.log('WERW:: ', userInfo);
        const res = await API.patch("/api/user/", userInfo);
        if ((res.status >= 200 && res.status < 400) && !res.data["detail"]) {
            return dispatch(updateUserInfo(userInfo));
        }
        if (res.data["detail"] && !(res.status >= 200 && res.status < 400)) {
            throw Error(res.data["detail"]);
        }
        if (res.data["non_field_errors"] && Array.isArray(res.data["non_field_errors"]) && res.data["non_field_errors"].length > 0) {
            throw Error(res.data["non_field_errors"][0]);
        }
        throw Error("internal server error");
    } catch (e) {
        dispatch(setSnackbarState({ open: true, message: e.message }));
    }
};

export const exchangeAxie =
    (user_pk, user_axie_pk, warehouse_axie_pk) => async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const res = await API.post("/api/exchange_axie/", {
                user_pk,
                user_axie_pk,
                warehouse_axie_pk,
            });
            // const res = {};
            if ((res.status >= 200 && res.status < 400) && !res.data["detail"]) {
                dispatch(decrementCounter());
                return dispatch(setSnackbarState({ open: true, axieExchangeError: 0, message: "Axie exchange successful" }));
            }
            if (res.data && Array.isArray(res.data["user_pk"]) && !(res.status >= 200 && res.status < 400)) {
                throw Error(`user ${res.data["user_pk"][0]}`);
            }
            if (res.data && Array.isArray(res.data["user_axie_pk"]) && !(res.status >= 200 && res.status < 400)) {
                throw Error(`user axie ${res.data["user_axie_pk"][0]}`);
            }
            if (res.data && Array.isArray(res.data["warehouse_axie_pk"]) && !(res.status >= 200 && res.status < 400)) {
                throw Error(`warehouse axie ${res.data["warehouse_axie_pk"][0]}`);
            }
            if (res.data && res.data["error"] && !(res.status >= 200 && res.status < 400)) {
                throw Error(res.data["error"]);
            }
            if (res.data && res.data["non_field_errors"] && Array.isArray(res.data["non_field_errors"]) && res.data["non_field_errors"].length > 0 && !(res.status >= 200 && res.status < 400)) {
                throw Error(res.data["non_field_errors"][0]);
            }
            throw Error("internal server error");
        } catch (e) {
            dispatch(setSnackbarState({ open: true, axieExchangeError: 1, message: e.message }));
        }
    };

export const requestWarehouseAxie =
    (limit = 5, offset = 0, query = "", counter = 0) =>
        async (dispatch) => {
            try {
                dispatch(setLoading(true));
                const url = query
                    ? `/api/axie_ware_house/?format=json&limit=${limit}&offset=${offset}&search=${query}`
                    : `/api/axie_ware_house/?format=json&limit=${limit}&offset=${offset}`;
                const res = await API.get(url);
                // const res = {};
                if ((res.status >= 200 && res.status < 400) && !res.data["detail"]) {
                    return dispatch(warehouseAxieRequestSuccess(res.data));
                }
                if (res.data["detail"] && !(res.status >= 200 && res.status < 400)) {
                    throw Error(res.data["detail"]);
                }
                throw Error("internal server error");
            } catch (e) {
                dispatch(setSnackbarState({ open: true, message: e.message }));
            }
        };

export const setSnackbarState = (data) => async (dispatch) => {
    if (data.message && (data.message === "Token is invalid or expired" || data.message === "token does not exist" || data.message === "user does not exist")) {
        setTimeout(() => dispatch(setSnack(data)), 200);
        localStorage.removeItem('token');
        return dispatch(logoutAuth());
    }
    dispatch(setSnack(data))
}

export const selectDashboard = (state) => state.dashboardReducer;

export const axieExchangeSelector = createDraftSafeSelector(
    selectDashboard,
    (state) => {
        return {
            isSnackOpen: state.snack.open,
            warehouseAxie: state.warehouseAxie,
            totalWarehouseAxie: state.totalWarehouseAxie,
            loading: state.loading,
            counter: state.counter,
            axieExchangeError: state.snack.axieExchangeError
        };
    }
);

export const dashboardIndexSelector = createDraftSafeSelector(
    selectDashboard,
    (state) => {
        console.log(1111);
        console.log('state: ', state);
        let a = {
            userInfo: state.userInfo,
            userAxie: state.userAxie,
            loading: state.loading,
            counter: state.counter,
            metamask_permission: state.metamask_permission
        };
        console.log(2222);
        return a;
    }
);

export const snackBarLayoutSelector = createDraftSafeSelector(
    selectDashboard,
    (state) => {
        return {
            ...state.snack,
            loading: state.loading,
        };
    }
);