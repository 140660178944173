import {Navigate, useRoutes} from "react-router-dom";
import React from "react";

import AuthLayout from "./modules/authModule/authLayout";
import BasePrevLayout from "./ui/basePrevLayout";

import PageNotFound from "./ui/404/PageNotFound";
import ApplyContainer from "./ui/apply/ApplyContainer";
import GiveAwayApplyContainer from "./ui/giveaway/GiveAwayApplyContainer";
import GiveAwayListContainer from "./ui/giveaway/list-page";
import DashboardLayout from "./modules/dashboardModule/dashboardLayout";

const LoginPage = React.lazy(() =>
  import("./modules/authModule/pages/login/Login")
);
const DashboardIndexPage = React.lazy(() =>
    import("./modules/dashboardModule/pages/dashboardIndex/dashboardIndex")
);
const DashboardAxieChangePage = React.lazy(() =>
    import("./modules/dashboardModule/pages/dashboardAxieChange/dashboardAxieChange")
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [{ path: "/", element: <LoginPage /> }],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "/dashboard", element: <DashboardIndexPage /> },
        { path: "/dashboard/change-axie", element: <DashboardAxieChangePage /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/apply",
      element: <BasePrevLayout showToolbar={false} />,
      children: [{ path: "/apply", element: <ApplyContainer /> }],
    },
    {
      path: "/axie-giveaway-sign-up",
      element: <Navigate to="/axie-giveaway" />
    },
    {
      path: "/axie-giveaway",
      element: <BasePrevLayout showToolbar={false} />,
      children: [
        { path: "/axie-giveaway", element: <GiveAwayListContainer /> },
        { path: "/axie-giveaway/:id", element: <GiveAwayApplyContainer /> },
      ],
    },
    { path: "*", element: <PageNotFound /> },
  ]);
}
