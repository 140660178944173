import "bootstrap/dist/css/bootstrap.min.css";

import footer_logo from '../assets/footer-logo.png'
import footer_top from '../assets/footer-top.png'
import fb from '../assets/social-icons/fb-icon.png'
import insta from '../assets/social-icons/insta.png'
import linkedin from '../assets/social-icons/linkedin.png'
import twitter from '../assets/social-icons/twitter.png'
import youtube from '../assets/social-icons/youtube.png'

export default function Footer() {
  return (
    <footer class="footer_outer">
      <div class="footer_top">
        <img 
					src={footer_top} 
					alt='footerTop'
				/>
      </div>
      <div class="container">
        <div class="footer_inner">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-12">
              <div class="footer_blk">
                <div class="footer_logo">
                  <img 
										src={footer_logo}
										alt="footerLogo"
									/>
                </div>
                <p>
                  "A platform where Gamers can find exciting new NFT Games and
                  Win Rewards."
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-4">
              <div class="footer_blk">
                <h5>Main</h5>
                <div class="menu-footer-menu1-container">
                  <ul id="menu-footer-menu1" class=" ">
                    <li>
                      <a
                        href="https://metaversejobjapan.io/"
                        aria-current="page"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/hot-games/">
                        mJJ Hot NFT Games
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/axie-giveaway/">
                        Giveaway
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/play-to-earn/">
                        NFT games
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-4">
              <div class="footer_blk">
                <h5>About us </h5>
                <div class="menu-footer-menu2-container">
                  <ul id="menu-footer-menu2" class=" ">
                    <li>
                      <a href="/about#mJJ-team">Meet our team</a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/contact/">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/list-your-game/">
                        List your Game
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/press-kit/">
                        Press kit
                      </a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/privacy-policy/">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-4">
              <div class="footer_blk">
                <h5>Latest</h5>
                <div class="menu-footer-menu3-container">
                  <ul id="menu-footer-menu3" class=" ">
                    <li>
                      <a href="https://metaversejobjapan.io/news-feed/">Blog</a>
                    </li>
                    <li>
                      <a href="https://metaversejobjapan.io/news/">News</a>
                    </li>
                  </ul>
                </div>{" "}
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="footer_blk">
                <h5>Follow Us</h5>
                <ul class="social_icon">
                  <li>
                    <a
                      href="https://www.facebook.com/metaversejobjapan"
                      target="_blank"
											rel="noreferrer"
                    >
                      <img 
												src={fb}
												alt="fb"
											/>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://twitter.com/metaverseJobJP"
                      target="_blank"
											rel="noreferrer"
                    >
                      <img 
												src={twitter}
												alt="twitter" 
											/>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/metaversejobjapan"
                      target="_blank"
											rel="noreferrer"
                    >
                      <img 
												src={insta}
												alt='instagram' 
											/>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/metaversejobjapan"
                      target="_blank"
											rel="noreferrer"
                    >
                      <img 
												src={linkedin}
												alt='linkedin'
											/>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/c/metaversejobjapan"
                      target="_blank"
											rel="noreferrer"
                    >
                      <img 
												src={youtube}
												alt='youtube'
											/>
                    </a>
                  </li>
                </ul>
                <div class="footer_btn_wrap">
                  <a
                    class="footer_btn"
                    href="https://metaversejobjapan.io/apply"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Connect Wallet
                  </a>
                  <a
                    class="footer_btn_login"
                    href="https://scholar.metaversejobjapan.io/"
                    target="_blank"
                    rel="noreferrer"
                    id="footer_login"
                  >
                    Scholar Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_footer">
        <div class="container">
          <span>© 2021- metaverse Job Japan, All Rights Reserved.</span>
        </div>
      </div>
    </footer>
  );
}
