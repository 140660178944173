import React, { memo } from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";

import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import logoBlackImage from '../../assets/do-to-win/logo-black.jpg';
import warningIcon from '../../assets/do-to-win/warning-icon.png';
import Header from "../../components/Header";
import Footer from "../../components/Footer";


const AlreadyAppliedContainer = ({ closeBtn }) => {

    return (
        <Stack
            direction="column"
            alignItems="center"
            style={{ height: "100%", color: "black", fontFamily: "product_sans" }}
        >
            {
                closeBtn && <div className="close-btn-popup-modal">
                    <IconButton style={{ color: "#7E7E7E" }} onClick={(e) => {
                        e && e.preventDefault();
                        window.location = "/axie-giveaway";
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            }
            <Header/>
            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", textAlign: "center", margin: "40px", }}>
                <img
                    src={logoBlackImage}
                    alt="logoBlackImage"
                    style={{
                        width: 264
                    }}
                />

                <img
                    src={warningIcon}
                    alt="warningIcon"
                    style={{
                        marginTop: 50,
                        marginBottom: 20,
                        width: 50,
                        height: 50,
                    }}
                />

                {/*<PlaylistAddCheckIcon style={{fontSize: "64px", color: "green", }} />*/}
                <h2 style={{ fontFamily: "google_sansbold", marginBottom: "0px", marginTop: "0px", }}>Already signed up for</h2>
                <h2 style={{ fontFamily: "google_sansbold", marginBottom: "30px", marginTop: "0px", }}>this Giveaway</h2>
                <div
                    className="flex-center ui-click-here-btn"
                    onClick={() => {
                        window.location = '/axie-giveaway';
                    }}
                    style={{padding: '8px 16px'}}
                >
                    <span style={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}> 
                        <i className="ui-arrow"></i>
                        Return to Giveaway
                    </span>
                </div>
            </div>
            <Footer/>
        </Stack>
    );
}

AlreadyAppliedContainer.propsType = {
    closeBtn: PropTypes.bool
};

export default memo(AlreadyAppliedContainer);
