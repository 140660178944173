import * as React from "react";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";

// import useScript from "../hooks/useScript";

import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { AppBar, CssBaseline, Toolbar } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import '../assets/ui_css.css';

const JoinDiscordButton = styled(Button)(() => ({
  textTransform: "none",
  backgroundColor: "#5865F2",
  border: "solid 2px #ffffff",
  ":hover": { backgroundColor: "#717cf4" },
}));

const App = (props) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // const [themeToggle] = React.useState("light");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
        },
      }),
    []
  );

  // useScript("/custom_script/fb.js");

  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <React.Fragment>
            <CssBaseline />
            {props.showToolbar && <>
              <Box sx={{ flexGrow: 1 }}>
                <AppBar>
                  <Toolbar>
                    <Box
                      component="a"
                      href="https://metaversejobjapan.io/how-to-apply/"
                      sx={{ flexGrow: 1, justifyContent: "flex-start" }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + `/img/logo-long.png`}
                        alt="logo"
                        style={{
                          width: "auto",
                          height: 50,
                          flexGrow: 1,
                          justifyContent: "flex-start",
                        }}
                      />
                    </Box>
                    {/* <ToggleThemeBtn
                  toggle={themeToggle}
                  setThemeToggle={() => setThemeToggle(!themeToggle)}
                /> */}
                    <JoinDiscordButton
                      color="inherit"
                      href="https://discord.com/invite/E5v7jtGmEj"
                      size="small"
                      variant="contained"
                      fullWidth={false}
                      startIcon={
                        <img
                          src={
                            process.env.PUBLIC_URL + `/img/Discord-Logo-White.svg`
                          }
                          style={{ width: 20, height: 20 }}
                          alt="Discord logo"
                        />
                      }
                    >
                      Join us on Discord
                    </JoinDiscordButton>
                  </Toolbar>
                </AppBar>
              </Box>
              <Toolbar />
            </>}
            <Outlet />
          </React.Fragment>
        </StyledEngineProvider>
      </ThemeProvider>
      <Box sx={{ display: { xs: 'none', md: 'block', } }}>
        <div id="fb-root" />
        <div id="fb-customer-chat" className="fb-customerchat" />
      </Box>
    </>
  );
};

App.propsType = {
  showToolbar: PropTypes.bool.isRequired
};

export default App;
