import React, {useReducer} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import ProvideInfoForm from "./ProvideInfoForm";
import {submitScholarshipApplication} from "../../api/main";
import {FullScreenDialogContainer} from "../../components/DialogUtils";
import ApplySuccessContainer from "./ApplySuccessContainer";
import logoImage from '../../assets/logo.svg';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AlreadyAppliedContainer from "./AlreadyAppliedContainer";

const initialState = {
    providedInfo: undefined,
    submitting: false,
    errorMessage: "",
    submitSuccess: false,
    isAlreadyApplied: false
};

const ApplyContainer = () => {
  const [state, setState] = useReducer(
    (_state, action) => ({ ..._state, ...action }),
    initialState
  );

  const handleErrorDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setState({ errorMessage: "" });
    }
  };

  const handleSuccessDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setState({ ...initialState, initialDialogOpen: false });
    }
  };

  const preparePayload = (statePayload) => {
      return {
        email: statePayload.email.toLowerCase(),
        first_name: statePayload.firstName.trim(),
        last_name: statePayload.lastName.trim(),
        // birth_date: dateToLocalISOString(statePayload.birthDate).split("T")[0],
        // gender: statePayload.gender,
        // twitter_username: statePayload.twitterUsername.trim(),
        // discord_username: statePayload.discordUsername.trim(),
        // ronin_address: statePayload.roninAddress,
        metamask_address: statePayload.metaMaskAddress,
        // country_of_residence: statePayload.countryOfResidence.iso_code,
    };
  };

  const handleSubmit = (statePayload) => {
    setState({ providedInfo: statePayload });
    const payload = preparePayload(statePayload);

    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }

    setState({ submitting: true });
    submitScholarshipApplication(formData).then((response) => {
      if (response.ok) {
        setState({ submitting: false, submitSuccess: true });
      } else {
        if (
          response.json.hasOwnProperty("email") &&
          response.json.email[0].code === "unique"
        ) {
          setState({
              submitting: false,
              isAlreadyApplied: true
          });
        } else {
          setState({
            submitting: false,
            errorMessage: "Something went wrong. Please contact support.",
          });
        }
      }
    });
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        minHeight: "100vh",
        fontFamily: "product_sans",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        maxWidth: "100vw",
        margin: "0px",
        padding: "0px"
      }}>
      <Header/>
      <Box
        maxWidth="sm"
        md={{
          // marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "100px 0px",
          margin: "auto",
          flex: "1",
        }}
        sx={{
          // marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "100px 15px",
          margin: "auto",
          flex: "1",
        }}
      >
        <img
          src={logoImage}
          alt="logo"
          style={{
            width: "auto",
            height: 70,
          }}
        />
        <Typography component="h1" variant="h5" margin="30px 0px 10px 0px" fontFamily="product_sans_bold" fontSize="32px" textAlign="center">
            Sign Up to Play
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <ProvideInfoForm parentState={state} handleSubmit={handleSubmit} />
        </Box>
      </Box>

      <Dialog open={!!state.errorMessage} onClose={handleErrorDialogClose}>
        <DialogTitle />
        <DialogContent>
          <DialogContentText color="error.main">
            {state.errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
      <FullScreenDialogContainer
        isOpen={state.submitSuccess}
        isNotCloseByOutSide={false}
        DialogInnerContent={() => <ApplySuccessContainer
          closeBtn={true}
          setOpen={handleSuccessDialogClose} />}
        dialogTitle={""}
        closeBtn={false}
        setOpen={handleSuccessDialogClose}
      />

        <FullScreenDialogContainer
            isOpen={state.isAlreadyApplied}
            isNotCloseByOutSide={false}
            DialogInnerContent={() => <AlreadyAppliedContainer
                closeBtn={true}
                // setOpen={handleSuccessDialogClose}
                // campaign={state.campaign}
            />}
            dialogTitle={""}
            closeBtn={false}
            setOpen={handleSuccessDialogClose}
        />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.submitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer/>
    </Container>
  );
};

export default ApplyContainer;
