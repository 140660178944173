import React, { useReducer, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  validateEmail,
  validateConfirmEmail,
  validateMetaMaskAddress,
} from "../../utils/common";
import {
  useAccount,
  useDisconnect,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react'
import { ReactComponent as ConnectedImg } from '../../assets/connected.svg';
import {ConnectButton} from "@rainbow-me/rainbowkit";
import Button from "@mui/material/Button";


const initialState = {
  email: "",
  confirmEmail: "",
  firstName: "",
  lastName: "",
  metaMaskAddress: "",
  submitted: false,
};

const isMobile = window.innerHeight > window.innerWidth;

const ProvideInfoForm = (props) => {
  const [state, setState] = useReducer(
    (_state, action) => ({ ..._state, ...action }),
    props.parentState.providedInfo
      ? props.parentState.providedInfo
      : initialState
  );

  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();

  const handleChange = async (event) => {
    if (event.target.name === "email") {
      setState({ [event.target.name]: event.target.value.trim() });
    } else {
      let val = event.target.value;
      if (val === "on") {
        val = true;
      } else if (val === "off") {
        val = false;
      }
      setState({ [event.target.name]: val });
    }
  };

  const validateFinalInput = () => {
    return !(
      !state.email ||
      !state.email.trim() ||
      !validateEmail(state.email) ||
      !state.confirmEmail.trim() ||
      !validateConfirmEmail(state.email, state.confirmEmail) ||
      !state.firstName ||
      !state.firstName.trim() ||
      !state.lastName ||
      !state.lastName.trim() ||
      !state.metaMaskAddress ||
      !validateMetaMaskAddress(state.metaMaskAddress)
    );
  };

  const handleSubmit = () => {
    if (validateFinalInput()) {
      props.handleSubmit(state);
    } else {
      setState({ submitted: true });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (address) {
      handleChange({ target: { name: "metaMaskAddress", value: address } })
    }
  }, [address])

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <FormControl
            required
            fullWidth
            style={!isConnected ? { display: "flex", justifyContent: "center", alignItems: "center", } : {}}
            error={
              state.submitted &&
              (!state.metaMaskAddress ||
                !validateMetaMaskAddress(state.metaMaskAddress))
            }
          >
            {!isConnected ? <ConnectButton label="Connect Your Wallet"/> : <>
              <OutlinedInput
                type="text"
                name="metaMaskAddress"
                value={state.metaMaskAddress}
                disabled={true}
                onChange={handleChange}
                label=''
                style={{ background: isConnected ? "#EAEAEA" : "transparent" }}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                        style={{
                          backgroundColor: "#FAB626",
                          textTransform: "none"
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          disconnect();
                          setState({metaMaskAddress: ""});
                        }}
                    >Change Wallet</Button>
                  </InputAdornment>
                }
              />
            </>}
            <FormHelperText style={{ marginTop: !isConnected ? "14px" : "0px", fontSize: "14px", fontFamily: "product_sans", marginLeft: "0px", textAlign: !isConnected ? "center" : "inherit", }}>
              {state.submitted && !state.metaMaskAddress ? (
                "Please Connect Your Wallet"
              ) : state.submitted &&
                !validateMetaMaskAddress(state.metaMaskAddress) ? (
                "Incorrect MetaMask address! Check if you mistakenly added whitespace at the beginning or end."
              ) : !isConnected ? (
                  <span style={{ fontFamily: "product_sans", }}>
                    Don’t have a wallet? {isMobile && <br/>}
                    Create after clicking the button
                  </span>
              ) : (
                <span style={{ fontFamily: "product_sans", }}>
                  Your wallet has been connected <ConnectedImg className="play-connected-svg" style={{ width: "14px", height: "12px", }} alt="connected to your wallet" />
                </span>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>

        {
          isConnected &&
          <>
            <Grid item xs={12}>
              <TextField
                error={
                  state.submitted &&
                  (!state.email ||
                    !state.email.trim() ||
                    !validateEmail(state.email))
                }
                required
                fullWidth
                placeholder="Email"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                name="email"
                value={state.email}
                onChange={handleChange}
                helperText={
                  state.submitted && (!state.email || !state.email.trim())
                    ? "This field is required"
                    : state.submitted && !validateEmail(state.email)
                      ? "Invalid email address"
                      : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={
                  state.submitted &&
                  (!state.confirmEmail ||
                    !state.confirmEmail.trim() ||
                    !validateEmail(state.confirmEmail) ||
                    !validateConfirmEmail(state.email, state.confirmEmail))
                }
                required
                fullWidth
                placeholder="Confirm Email (Emailを再度入力)"
                onCut={(e) => {
                  e.preventDefault();
                }}
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                name="confirmEmail"
                value={state.confirmEmail}
                onChange={handleChange}
                helperText={
                  state.submitted &&
                    (!state.confirmEmail || !state.confirmEmail.trim())
                    ? "This field is required"
                    : state.submitted &&
                      (!validateEmail(state.confirmEmail) ||
                        !validateConfirmEmail(state.email, state.confirmEmail))
                      ? "Invalid email address or not same email"
                      : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={
                  state.submitted && (!state.firstName || !state.firstName.trim())
                }
                name="firstName"
                required
                fullWidth
                value={state.firstName}
                placeholder="First Name 名（ローマ字)"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                helperText={
                  state.submitted &&
                  (!state.firstName || !state.firstName.trim()) &&
                  "This field is required"
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={
                  state.submitted && (!state.lastName || !state.lastName.trim())
                }
                required
                fullWidth
                placeholder="Last Name 姓 (ローマ字)"
                name="lastName"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                helperText={
                  state.submitted &&
                  (!state.lastName || !state.lastName.trim()) &&
                  "This field is required"
                }
                value={state.lastName}
                onChange={handleChange}
              />
            </Grid>
          </>
        }

      </Grid>

      {isConnected && <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div
            className="flex-center ui-submit-play-btn"
            onClick={handleSubmit}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}> Submit </span>
          </div>
        </div>

      </Box>}
    </>
  );
};

export default ProvideInfoForm;
