import { memo, useState } from "react";
import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";

import afterSuccessSVG from '../../assets/afterSuccessBG.svg';
import successTickSVG from '../../assets/success_tick.svg';
import emailSVG from '../../assets/email.svg';
import discordLogo from '../../assets/Discord-Logo-White.svg';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const JoinDiscordButton = styled(Button)(() => ({
    textTransform: "none",
    backgroundColor: "#5865F2",
    padding: "8px 12px",
    fontSize: "13px",
    marginLeft: "8px",
    fontWeight: "bold",
    boxShadow: "none",
    ":hover": { backgroundColor: "#717cf4", boxShadow: "none", },
}));

const ApplySuccessContainer = ({ closeBtn, setOpen }) => {

    const [joinDiscord, setJoinDiscord] = useState(false);

    return (
        <Stack
            direction="column"
            alignItems="center"
            style={{ height: "100%", color: "black", fontFamily: "product_sans" }}
        >
            {
                closeBtn && <div className="close-btn-popup-modal">
                    <IconButton style={{ color: "#7E7E7E" }} onClick={(e) => {
                        e && e.preventDefault();
                        setOpen(false);
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", textAlign: "center", margin: "40px", }}>
                <img alt="success" src={successTickSVG} />
                <h1 style={{ fontFamily: "google_sansbold", marginBottom: "5px", }}>Congratulations!</h1>
                <span style={{fontSize: "14px", color: "#3E3A39", }}>Registration is complete.</span>
                <div className="verify-email-container">
                    <img src={emailSVG} style={{ width: "18px", height: "18px", marginRight: "8px", }} alt="email icon" />  Verify  email to start playing
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "15px", }}>
                    <span style={{ color: "#3E3A39", fontSize: "13px", }}>For more information </span>
                    <JoinDiscordButton
                        color="inherit"
                        onClick={() => setJoinDiscord(true)}
                        size="small"
                        variant="contained"
                        className="discord-btn"
                        fullWidth={false}
                        style={{ color: "white", }}
                        startIcon={
                            <img
                                src={discordLogo}
                                style={{ width: 16, height: 16 }}
                                alt="Discord logo"
                            />
                        }
                    >
                        Join Discord
                    </JoinDiscordButton>
                </div>
            </div>
            <div className="background-container" style={{ width: "100%" }}>
                <img src={afterSuccessSVG} alt="giveaway-bg" />
            </div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={joinDiscord}
                onClick={() => setJoinDiscord(false)}
            >
                <Stack spacing={1} style={{ height: "100%" }}>
                    <iframe
                        title="Connect to Discord server"
                        src="https://discord.com/widget?id=894910457834508288&theme=dark"
                        width="350"
                        height="500"
                        allowtransparency="true"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                    />
                </Stack>
            </Backdrop>
        </Stack>
    );
}

ApplySuccessContainer.propsType = {
    closeBtn: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
};

export default memo(ApplySuccessContainer);