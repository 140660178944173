import * as React from "react";
import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
    lightTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import Router from "./routes";


const { chains, provider } = configureChains(
    [mainnet],
    [
        alchemyProvider({ apiKey: 'P4plRe80QOTWm2c9N59PvYkByl9QFjj6' }),
        publicProvider()
    ],
)

const { connectors } = getDefaultWallets({
    // appName: 'My RainbowKit App',
    chains
});

const wagmiClient = createClient({
    // autoConnect: true,  // https://wagmi.sh
    connectors,
    provider
})


const App = () => {
  return (
      <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider
              chains={chains}
              theme={lightTheme({
                  accentColor: '#EA5506',
                  borderRadius: 'small'
              })}
          >
              <React.Fragment>
                  <Router />
              </React.Fragment>
          </RainbowKitProvider>
      </WagmiConfig>
  );
};

export default App;
