import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Backdrop,
  CircularProgress
} from "@mui/material";
import ProvideInfoForm from "./ProvideInfoForm";
import {applyForGiveaway, getGiveAwayCampaignItem, giveawayApplicantSignUp} from "../../api/maas";
import { FullScreenDialogContainer } from "../../components/DialogUtils";
import ApplySuccessContainer from "./ApplySuccessContainer";
import ApplyFailedContainer from "./ApplyFailedContainer";
import logoImage from '../../assets/logo.svg';
import AlreadyAppliedContainer from "./AlreadyAppliedContainer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const initialState = {
    submitting: false,
    errorMessage: "",
    campaign: {},
    isCampaignLoading: false,
    submitSuccess: false,
    isAlreadyApplied: false
};

const ApplyContainer = () => {
  const [state, setState] = useReducer(
    (_state, action) => ({ ..._state, ...action }),
    initialState
  );

  const { id } = useParams();

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  React.useEffect(() => {
    if (id && id.length > 0 && isNumeric(id)) {
      setState({isCampaignLoading: true});
      getGiveAwayCampaignItem(id).then(val => {
        setState({isCampaignLoading: false, campaign: val['json']});
      }).catch(err => {
        console.log('ER: ', err);
        setState({isCampaignLoading: false});
      })
    }
  }, [id]);

  const handleErrorDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setState({ errorMessage: "" });
    }
  };

  const handleSuccessDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setState({ ...initialState, initialDialogOpen: false });
    }
  };

  const handleSignUp = payload => {
    setState({ submitting: true });
      giveawayApplicantSignUp(payload).then((response) => {
      if (response.ok) {
          const token = response.json.token;
          const is_account_created = response.json.is_account_created;
          if (token) {
              handleApply(token);
          }
          if(is_account_created) {
            setState({is_account_created: is_account_created})
          }
      } else {
          setState({submitting: false});
      }
    });
  };

    const handleApply = token => {
        const payload = {
            token: token,
            campaign: state.campaign.id
        };

        setState({ submitting: true });
        applyForGiveaway(payload).then((response) => {
            if (response.ok) {
                const created = response.json.created;
                if (created) {
                    setState({ submitting: false, submitSuccess: true });
                } else {
                    setState({ submitting: false, isAlreadyApplied: true });
                }
                // setState({ submitting: false, submitSuccess: true });
            } else {
                setState({submitting: false});
            }
        });
    };

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        minHeight: "100vh",
        fontFamily: "product_sans",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        maxWidth: "100vw",
        margin: "0px",
        padding: "0px"
      }}>
      <Header/>
      <Box
        maxWidth="sm"
        md={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "200px 0px",
          margin: "auto",
          flex: "1",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "200px 15px",
          margin: "auto",
          flex: "1",
        }}
      >
        <img
          src={logoImage}
          alt="logo"
          style={{
            width: "auto",
            height: 70,
          }}
        />
        <Typography component="h1" variant="h5" margin="30px 0px 10px 0px" fontFamily="product_sans_bold" fontSize="35px" textAlign="center">
          Sign Up For Our Give Away
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <ProvideInfoForm parentState={state} handleApply={handleApply} campaign={state.campaign} handleSignUp={handleSignUp} />
        </Box>
      </Box>
      <FullScreenDialogContainer
        isOpen={state.submitSuccess}
        isNotCloseByOutSide={false}
        DialogInnerContent={() => <ApplySuccessContainer
          closeBtn={false}
          // setOpen={handleSuccessDialogClose}
          campaign={state.campaign}
          is_account_created={state.is_account_created || false}
        />}
        dialogTitle={""}
        closeBtn={false}
        setOpen={handleSuccessDialogClose}
      />

        <FullScreenDialogContainer
            isOpen={state.isAlreadyApplied}
            isNotCloseByOutSide={false}
            DialogInnerContent={() => <AlreadyAppliedContainer
                closeBtn={true}
                // setOpen={handleSuccessDialogClose}
                // campaign={state.campaign}
            />}
            dialogTitle={""}
            closeBtn={false}
            setOpen={handleSuccessDialogClose}
        />

      <FullScreenDialogContainer
        isOpen={!!state.errorMessage}
        isNotCloseByOutSide={false}
        DialogInnerContent={() => <ApplyFailedContainer
          closeBtn={true}
          setOpen={handleErrorDialogClose}
        />}
        dialogTitle={""}
        closeBtn={false}
        setOpen={handleErrorDialogClose}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.submitting || state.isCampaignLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer/>
    </Container>
  );
};

export default ApplyContainer;
