import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from "@web3modal/ethereum";

import { configureChains, createClient } from "wagmi";
import { mainnet } from "wagmi/chains";
import { REACT_APP_WALLET_CONNECT_PROJECT_ID } from "../utils/constants";

const chains = [ mainnet ];
export const projectId = REACT_APP_WALLET_CONNECT_PROJECT_ID;

const { provider } = configureChains(chains, [
    w3mProvider({ projectId: projectId, }),
]);

// Wagmi Client
export const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId: projectId, version: 1, chains }),
    provider,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiClient, chains);
