export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateOTP = (otp) => {
  const regex = /[A-Za-z0-9]{6}/gm;
  return otp.length === 6 && regex.test(otp);
};

export const validateConfirmEmail = (email, confirmEmail) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    re.test(String(confirmEmail).toLowerCase()) &&
    confirmEmail.toLowerCase() === email.toLowerCase()
  );
};

export const validateDiscordUsername = (discordUsername) => {
  const re = /^((?!(discordtag|everyone|here)#)((?!@|#|:|```).{2,32})#\d{4})$/;
  return re.test(discordUsername);
};

export const validateRoninAddress = (address) => {
  const re = /^ronin:[A-Fa-f0-9]{40}$/;
  return re.test(address);
};

export const validateMetaMaskAddress = (address) => {
  const re = /^0x[A-Fa-f\d]{40}$/;
  return re.test(address);
};

export const calculateAge = (birthDate) => {
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const dateToLocalISOString = (date) => {
  const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzOffset).toISOString();
};
