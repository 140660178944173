import {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
// redux
import { useSelector, useDispatch } from "react-redux";

// actions and selectors
import {
  selectUser,
  sendEmail,
  logout,
  logoutAuth,
  sendEmailWithPassCode,
} from "../modules/authModule/redux/redux";
import useEventListener from "./useEventListener";

const authContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const userState = useSelector(selectUser);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const checkEmail = (email) => {
    if (userState.user !== null) {
      return;
    }
    dispatch(sendEmail({ email: email }));
  };

  const signIn = (email, passCode) => {
    if (userState.user !== null) {
      return;
    }
    dispatch(sendEmailWithPassCode({ email: email, passCode: passCode }));
  };

  // Event handler utilizing useCallback ...
  // ... so that reference never changes.
  const handler = useCallback(
    () => {
      if (!userState.user) {
        dispatch(logoutAuth());
      }
    },
    [dispatch, userState.user]
  );

  useEventListener("beforeunload", handler);

  const signOut = useCallback(() => {
    if (userState.user !== null) {
      dispatch(logoutAuth());
      dispatch(logout());
    }
  }, [dispatch, userState.user]);

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    setUser(userState.user);
  }, [userState.user]);

  // 1 hour time limit
  useEffect(() => {
    const timer = setTimeout(() => {
      signOut();
    }, userState.loginTime - Date.now());

    return () => clearTimeout(timer);
  }, [userState.loginTime, signOut]);

  // Return the user object and auth methods
  return {
    user,
    checkEmail,
    signIn,
    signOut,
  };
}
