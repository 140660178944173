import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useEventListener from "../../hooks/useEventListener";
import BaseLayout from "../../layouts/baseLayout";

const DashboardLayout = () => {
    const auth = useAuth();

    // Event handler utilizing useCallback ...
    // ... so that reference never changes.
    const handler = useCallback(
        ({ key, oldValue, newValue }) => {
            // logout if key removed
            if (key === "token" && oldValue && !newValue) {
                auth.signOut();
            }
        },
        [auth]
    );

    useEventListener("storage", handler);

    return (
        <BaseLayout>
            <Outlet />
        </BaseLayout>
    );
};

export default DashboardLayout;
