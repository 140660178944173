import {Link} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const PageNotFound = () =>
    <>
    <Header/>
    <Container component="main" maxWidth="sm" style={{padding: '200px 0px'}}>
        <Box sx={{marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant="h5" gutterBottom component="div">
                404 - Page not found
            </Typography>
            <Link to="/">Home</Link>
        </Box>
    </Container>
    <Footer/>
    </>

export default PageNotFound;
