import { useState } from "react";
import logo from '../assets/logo.svg'

export default function Header() {
  const [panelOpen, setPanelOpen] = useState(false);
  return (
    <header class={`header_outer add_shadow ${panelOpen ? "addPnnl" : ""}`}>
      <div class="container">
        <div class="hddr_inr">
          <div class="logo">
            <a href="https://metaversejobjapan.io/">
              <img
                style={{ width: "140px" }}
                src={logo}
                alt="headerlogo"
              />
            </a>
          </div>
          <div class="menu_trigger">
            <div
              class="menu_btn"
              onClick={() => {
                setPanelOpen(!panelOpen)
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="hdr_menu">
            <div class="menu-header-menu-container">
              <ul id="menu-header-menu" class="">
                <li>
                  <a href="https://metaversejobjapan.io/hot-games/">
                    NFT Games
                  </a>
                </li>
                <li>
                  <a href="https://metaversejobjapan.io/axie-giveaway/">
                    Giveaway
                  </a>
                </li>
                <li>
                  <a href="https://metaversejobjapan.io/news-feed/">News</a>
                </li>
                <li>
                  <a href="https://metaversejobjapan.io/faq">FAQ</a>
                </li>
              </ul>
            </div>

            <a
              class="solid_btn"
              id="header_apply"
              href="https://metaversejobjapan.io/apply"
              target="_blank"
              rel='noreferrer'
            >
              Connect Wallet
            </a>
            <a
              class="solid_btn"
              id="header_login"
              href="https://scholar.metaversejobjapan.io/"
              target="_blank"
              rel='noreferrer'
            >
              Scholar Log in
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
