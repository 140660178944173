import { prepareApiResponse } from "./utils";
import axios from "axios";

export const submitScholarshipApplication = async (formData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/main/apply_for_scholarship/`,
      {
        method: "POST",
        body: formData,
      }
    );
    return prepareApiResponse(response);
  } catch (error) {
    console.error(error);
  }
};

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/dashboard`,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
  validateStatus: function (status) {
    return status >= 200 // && status < 400
  }
});

API.interceptors.request.use(
  (config) => {
    if (config.url?.includes("/api/")) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;
