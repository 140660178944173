import React, {useReducer, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import {
  validateEmail,
  validateConfirmEmail,
  validateMetaMaskAddress,
} from "../../utils/common";
import {
  checkIfWalletExists,
  createRandomMessage,
} from "../../api/maas";
import {
  useAccount,
  useSignMessage,
} from 'wagmi';
import { disconnect } from '@wagmi/core';
import { ReactComponent as ConnectedImg } from '../../assets/connected.svg';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import MuiAlert from '@mui/material/Alert';
import {ConnectButton} from "@rainbow-me/rainbowkit";


function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialState = {
  email: "",
  confirmEmail: "",
  firstName: "",
  lastName: "",
  metaMaskAddress: "",
  submitted: false,
};

const initialStateForWalletSign = {
  isSigned: false,
  isAuthenticating: false,
  signature: '',
  address: '',
  message: '',
  err: ''
}

const isMobile = window.innerHeight > window.innerWidth;

const ProvideInfoForm = (props) => {
  const [state, setState] = useReducer(
    (_state, action) => ({ ..._state, ...action }),
    props.parentState.providedInfo
      ? props.parentState.providedInfo
      : initialState
  );

  const [walletSignState, setWalletSignState] = useReducer(
    (_state, action) => ({ ..._state, ...action }),
    initialStateForWalletSign
  );

  const { isConnected, address } = useAccount();

  const { signMessage } = useSignMessage({
    onSuccess: (data, variables, ctx) => {
      const payload = {
        wallet_address: address,
        signature: data,
        message: variables['message']
      }

      checkIfWalletExists(payload).then((response) => {
        if (response.ok) {
          const token = response.json.token;
          if (token) {
            props.handleApply(token);
          }
        }
      });

      setWalletSignState({
        isSigned: true,
        signature: data,
        message: variables['message'],
        address: address
      })
    },
    onError: (err, variables, ctx) => {
      console.log('On Error');
      disconnect();
      setWalletSignState({
        isSigned: false,
        // err: 'Error Occurred During Connection, Please Try Again'
      });
    }
  })


  const handleChange = async (event) => {
    if (event.target.name === "email") {
      setState({ [event.target.name]: event.target.value.trim() });
    } else {
      let val = event.target.value;
      if (val === "on") {
        val = true;
      } else if (val === "off") {
        val = false;
      }
      setState({ [event.target.name]: val });
    }
  };

  const validateFinalInput = () => {
    return !(
      !state.email ||
      !state.email.trim() ||
      !validateEmail(state.email) ||
      !state.confirmEmail.trim() ||
      !validateConfirmEmail(state.email, state.confirmEmail) ||
      !state.firstName ||
      !state.firstName.trim() ||
      !state.lastName ||
      !state.lastName.trim() ||
      !state.metaMaskAddress ||
      !validateMetaMaskAddress(state.metaMaskAddress)
    );
  };

  const handleSignUp = () => {
    if (validateFinalInput()) {
      const payload = {
        email: state.email.toLowerCase().trim(),
        first_name: state.firstName.trim(),
        last_name: state.lastName.trim(),
        wallet_address: state.metaMaskAddress,
        message: walletSignState.message,
        signature: walletSignState.signature
      }
      props.handleSignUp(payload);
    } else {
      setState({ submitted: true });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (address) {
      createRandomMessage().then(response => {
        const message = response.json.message;
        signMessage({ message });
        handleChange({ target: { name: "metaMaskAddress", value: address } })
      }).catch(err => {
        console.log('ER: ', err);
      })
    }
  }, [address])

  useEffect(() => {
    disconnect();
  }, [disconnect]);

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <FormControl
            required
            fullWidth
            style={(!isConnected && !walletSignState.isSigned) ? { display: "flex", justifyContent: "center", alignItems: "center", } : {}}
            error={
              state.submitted &&
              (!state.metaMaskAddress ||
                !validateMetaMaskAddress(state.metaMaskAddress))
            }
          >
            {(!isConnected || !walletSignState.isSigned) ?
                !isConnected ? <ConnectButton label="Connect Your Wallet"/> : <span style={{ fontFamily: "product_sans", color: '#EA5505', fontWeight: 700 }}>
                    Open Wallet to Sign up {isMobile && <br/>}
                    and return to this page
                  </span>
                :
                <>
              <OutlinedInput
                type="text"
                name="metaMaskAddress"
                value={state.metaMaskAddress}
                disabled={true}
                onChange={handleChange}
                label=''
                style={{ background: (isConnected && walletSignState.isSigned) ? "#EAEAEA" : "transparent" }}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                        style={{
                          backgroundColor: "#EA5506",
                          textTransform: "none"
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          disconnect();
                          setState({metaMaskAddress: ""});
                          setWalletSignState({
                            isSigned: false,
                            isAuthenticating: false,
                            signature: '',
                            address: '',
                            message: '',
                            err: ''
                          });
                        }}
                    >Change Wallet</Button>
                  </InputAdornment>
                }
              />
            </>}
            <FormHelperText style={{ marginTop: (!isConnected && !walletSignState.isSigned) ? "14px" : "0px", fontSize: "14px", fontFamily: "product_sans", marginLeft: "0px", textAlign: (!isConnected && !walletSignState.isSigned) ? "center" : "inherit", }}>
              {state.submitted && !state.metaMaskAddress ? (
                "Please Connect Your Wallet"
              ) : state.submitted &&
                !validateMetaMaskAddress(state.metaMaskAddress) ? (
                "Incorrect MetaMask address! Check if you mistakenly added whitespace at the beginning or end."
              ) : (!isConnected || !walletSignState.isSigned) ? (
                  !isConnected && <span style={{ fontFamily: "product_sans", }}>
                    Don’t have a wallet? {isMobile && <br/>}
                    Create after clicking the button
                  </span>
              ) : (
                <span style={{ fontFamily: "product_sans", }}>
                  Your wallet has been connected <ConnectedImg style={{ width: "14px", height: "12px", }} alt="connected to your wallet" />
                </span>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        {
          (isConnected && walletSignState.isSigned) &&
          <>
            <Grid item xs={12}>
              <TextField
                error={
                  state.submitted &&
                  (!state.email ||
                    !state.email.trim() ||
                    !validateEmail(state.email))
                }
                required
                fullWidth
                placeholder="Email"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                name="email"
                value={state.email}
                onChange={handleChange}
                helperText={
                  state.submitted && (!state.email || !state.email.trim())
                    ? "This field is required"
                    : state.submitted && !validateEmail(state.email)
                      ? "Invalid email address"
                      : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={
                  state.submitted &&
                  (!state.confirmEmail ||
                    !state.confirmEmail.trim() ||
                    !validateEmail(state.confirmEmail) ||
                    !validateConfirmEmail(state.email, state.confirmEmail))
                }
                required
                fullWidth
                placeholder="Confirm Email (Emailを再入力)"
                onCut={(e) => {
                  e.preventDefault();
                }}
                onCopy={(e) => {
                  e.preventDefault();
                }}
                onPaste={(e) => {
                  e.preventDefault();
                }}
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                name="confirmEmail"
                value={state.confirmEmail}
                onChange={handleChange}
                helperText={
                  state.submitted &&
                    (!state.confirmEmail || !state.confirmEmail.trim())
                    ? "This field is required"
                    : state.submitted &&
                      (!validateEmail(state.confirmEmail) ||
                        !validateConfirmEmail(state.email, state.confirmEmail))
                      ? "Invalid email address or not same email"
                      : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={
                  state.submitted && (!state.firstName || !state.firstName.trim())
                }
                name="firstName"
                required
                fullWidth
                value={state.firstName}
                placeholder="First Name 名（ローマ字)"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                helperText={
                  state.submitted &&
                  (!state.firstName || !state.firstName.trim()) &&
                  "This field is required"
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={
                  state.submitted && (!state.lastName || !state.lastName.trim())
                }
                required
                fullWidth
                placeholder="Last Name 姓 (ローマ字)"
                name="lastName"
                sx={{
                  "& p": { color: "info.main" },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFD783",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root.Mui-error": {
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputBase-root:hover": {
                    "& fieldset": {
                      borderColor: "#F8B629",
                      borderWidth: "2px",
                    },
                  },
                }}
                helperText={
                  state.submitted &&
                  (!state.lastName || !state.lastName.trim()) &&
                  "This field is required"
                }
                value={state.lastName}
                onChange={handleChange}
              />
            </Grid>
          </>
        }

      </Grid>

      {(isConnected && walletSignState.isSigned) && <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div
            className="flex-center ui-submit-btn"
            onClick={handleSignUp}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}> Submit </span>
          </div>
        </div>

      </Box>}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={walletSignState.isAuthenticating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={walletSignState.err.length > 0}
        onClose={() => {
          disconnect();
          setWalletSignState({ err: '', });
        }}
        TransitionComponent={TransitionRight}
        key={TransitionRight ? TransitionRight.name : ''}
      >
        <Alert
          onClose={() => {
            disconnect();
            setWalletSignState({ err: '', });
          }}
          severity="error">
          {walletSignState.err}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProvideInfoForm;
